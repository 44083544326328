<template>
  <div class="OrderMaterials">
    <div v-for="(item, index) in form" :key="index">
      <div v-if="item.details.length > 0" class="marginTop">
        <a-form-model
          :model="form[index]"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          ref="ruleForm"
        >
          <div class="formFlex">
            <a-form-model-item label="收货人" prop="receivingName">
              <a-input v-model="form[index].receivingName" />
            </a-form-model-item>
            <a-form-model-item label="联系电话" prop="phone">
              <a-input v-model="form[index].phone" />
            </a-form-model-item>
            <a-form-model-item label="配送方式">
              <a-select
                v-model="form[index].deliveryMethod"
                placeholder="请选择配送方式"
              >
                <a-select-option
                  v-for="item in deliveryMethodList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="收货地址">
              <a-input v-model="form[index].receivingAddress" />
            </a-form-model-item>
          </div>

          <!-- <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="onSubmit(index)"> 下单 </a-button>
          </a-form-model-item> -->
        </a-form-model>
        <!--   :rowSelection="rowSelection" -->
        <a-table
          ref="table"
          size="small"
          rowKey="cmId"
          :columns="tableColumns"
          :data-source="form[index].details"
          :pagination="false"
          :scroll="{ x: '1400px', y: '150px' }"
          tableLayout="fixed"
          :row-selection="rowSelection"
        >
          <!-- 材料编号 -->
          <span slot="cmMOnly" slot-scope="text, record">
            <div>{{ record.tmaterial.mNumber }}</div>
          </span>
          <!-- 材料类别 -->
          <span slot="mtName" slot-scope="text">
            <a-tooltip>
              <template slot="title">
                {{ text }}
              </template>
              {{ text }}
            </a-tooltip>
          </span>
          <!-- 材料名称 -->
          <span slot="mName" slot-scope="text">
            <a-tooltip>
              <template slot="title">
                {{ text }}
              </template>
              {{ text }}
            </a-tooltip>
          </span>

          <!--   下单数量 -->
          <span slot="cmQuantity" slot-scope="text, record">
            <div>{{ record.cmQuantity - record.cmOrderQuantity }}</div>
          </span>
          <!-- 应下单日期 -->
          <span slot="cmEarlyOrderTime" slot-scope="text, record">
            <div v-if="text == ''">{{ "-" }}</div>
            <div v-else>
              {{ $Format(text).slice(0, 10) }}~{{
                $Format(record.cmLateOrderTime).slice(0, 10)
              }}
            </div>
          </span>
          <!-- 供应商 -->
          <span slot="cmSName" slot-scope="text">
            <a-tooltip>
              <template slot="title">
                {{ text }}
              </template>
              {{ text }}
            </a-tooltip>
          </span>
          <!-- 收货地址 -->
          <span slot="tworksite" slot-scope="text">
            <a-tooltip>
              <template slot="title">
                {{ text.wsName }}
              </template>
              {{ text.wsName }}
            </a-tooltip>
          </span>
          <!-- 操作 -->
          <span slot="action" slot-scope="text, record" class="action">
            <a class="editBtn" @click="deleteClick(record, index)">删除</a>
          </span>
        </a-table>
      </div>
    </div>
    <div class="clickBtns">
      <a-button type="primary" @click="onSubmit"> 下单 </a-button>
      <a-button class="cancelBtn" @click="GoToWaitingOrder"> 取消 </a-button>
    </div>
  </div>
</template>
<script>
import { STable } from "@/components";
import { AddOrderconstructionMaterial } from "@/api/apiJF/material";

export default {
  name: "OrderMaterials",
  components: {
    STable,
  },
  data() {
    return {
      tableColumns: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      form: [
        {
          receivingName: "",
          phone: "",
          deliveryMethod: "",
          receivingAddress: "",
          details: [],
        },
        {
          receivingName: "",
          phone: "",
          deliveryMethod: "",
          receivingAddress: "",
          details: [],
        },
        {
          receivingName: "",
          phone: "",
          deliveryMethod: "",
          receivingAddress: "",
          details: [],
        },
        {
          receivingName: "",
          phone: "",
          deliveryMethod: "",
          receivingAddress: "",
          details: [],
        },
        {
          receivingName: "",
          phone: "",
          deliveryMethod: "",
          receivingAddress: "",
          details: [],
        },
        {
          receivingName: "",
          phone: "",
          deliveryMethod: "",
          receivingAddress: "",
          details: [],
        },
      ],
      rules: {
        receivingName: [
          {
            required: true,
            message: "请填写收货人",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请填写收货人手机号",
            trigger: "blur",
          },
          { min: 11, max: 11, message: "Length should be 11", trigger: "blur" },
        ],
      },
      deliveryMethodList: [
        { value: 1, label: "送货上门" },
        { value: 2, label: "集中配送" },
        { value: 3, label: "门店自取" },
      ],
      selectedRowKeys: [],
      selectedRows: [],

      FormList: [],
      newForm: [],
      tableColumns: [
        {
          title: "材料编号",
          width: "100px",
          dataIndex: "cmMOnly",
          scopedSlots: { customRender: "cmMOnly" },
          ellipsis: true,
          align: "center",
        },
        {
          title: "材料类别",
          width: "150px",
          dataIndex: "tmaterial.tmaterialType.mtName",
          scopedSlots: { customRender: "mtName" },
        },
        {
          title: "材料名称",
          width: "150px",
          scopedSlots: { customRender: "mName" },
          dataIndex: "tmaterial.mName",
          // align: "center",
          ellipsis: true,
        },
        {
          title: "型号",
          width: "100px",
          dataIndex: "tmaterial.mModel",
          align: "center",
        },
        {
          title: "单位",
          width: "90px",
          dataIndex: "tmaterial.mUnit",
          align: "center",
        },
        {
          title: "单价(元)",
          width: "90px",
          dataIndex: "tmaterial.mSalesPrice",
          align: "center",
        },
        {
          title: "下单数量",
          width: "90px",
          dataIndex: "cmQuantity",
          scopedSlots: { customRender: "cmQuantity" },
          align: "center",
        },
        {
          title: "应下单日期",
          width: "200px",
          scopedSlots: { customRender: "cmEarlyOrderTime" },
          dataIndex: "cmEarlyOrderTime",
          align: "center",
        },
        {
          title: "供应商",
          width: "200px",
          dataIndex: "cmSName",
          // align: "center",
          scopedSlots: { customRender: "cmSName" },
          ellipsis: true,
        },
        {
          title: "收货地址",
          // width: "100px",
          dataIndex: "tworksite",
          // align: "center",
          scopedSlots: { customRender: "tworksite" },
          ellipsis: true,
        },
      ],
    };
  },
  created() {},
  mounted() {
    let tConstructionMaterials = JSON.parse(
      this.$route.query.tConstructionMaterials
    );
    let newList = this.sortArr(tConstructionMaterials, "cmWsId");
    // console.log("newList", newList);
    newList.forEach((el, index) => {
      this.form[index].details = el;
      this.form[index].receivingAddress = el[0].tworksite.wsName;
    });
    // console.log(" this.form", this.form);
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        // onSelection: this.onSelection,
      };
    },
  },
  methods: {
    onSubmit() {
      console.log("submit!", this.newForm);
      let indexMax = -1;
      // this.newForm=  this.newForm.filter((el) => el.details.length > 0);

      this.newForm.forEach((el, index) => {
        if (el.details.length > 0) {
          indexMax = index;
        }
      });
      // console.log("indexMax", indexMax);
      // indexMax==-1时代表没勾选发货信息
      if (indexMax == -1) {
        this.$message.warn("请至少选择一项进行下单");
      } else {
        // this.$message.success("下单成功");
        let indexMax1 = -1;
        this.newForm.forEach((el, index) => {
          if (el.details.length > 0) {
            let Name = this.form[index].receivingName;
            let phone = this.form[index].phone;
            let Method = this.form[index].deliveryMethod;
            let address = this.form[index].receivingAddress;
            if (Name != "" && phone != "" && Method != "" && address != "") {
              // this.$message.success("成功");
            } else {
              // this.$message.success("请填写完整收货信息");
              indexMax1 = index;
            }
            this.newForm[index].receivingName = Name;
            this.newForm[index].phone = phone;
            this.newForm[index].deliveryMethod = Method;
            this.newForm[index].receivingAddress = address;
            //
          }
        });
        console.log("indexMax1", indexMax1);
        let params = this.newForm.filter((el) => el.details.length > 0);
        // 代表所有发货信息填写完整
        if (indexMax1 == -1) {
          console.log("params", params);
          this.AddOrderconstructionMaterial(params);
        } else {
          this.$message.warn("请填写完整收货信息");
        }
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("aaaaaaa", selectedRowKeys, selectedRows);
      this.filterFormTable(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      // this.form.tConstructionMaterials = this.selectedRows;
    },
    // 查找form里表格内包含的选中项
    filterFormTable(selectedRowKeys) {
      this.newForm = JSON.parse(JSON.stringify(this.form));
      this.form.forEach((el, index) => {
        this.newForm[index].details = [];
        if (el.details.length > 0) {
          el.details.forEach((item) => {
            if (selectedRowKeys.indexOf(item.cmId) > -1) {
              this.newForm[index].details.push(item);
            }
          });
        }
      });
      // console.log("this.newForm", this.newForm);
    },
    // 物料下单
    AddOrderconstructionMaterial(params) {
      // console.log("params", params);
      AddOrderconstructionMaterial(params).then((res) => {
        console.log("物料下单", res);
        if (res.code == 200) {
          this.$message.success("下单成功");
          this.$router.push({
            path: "/WaitingOrder",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    deleteClick(record, index) {
      console.log("deleteClick", record, index);
      let details = this.form[index].details;
      details = details.filter((item) => {
        return item.cmId != record.cmId;
      });
      console.log("details", details);
      this.form[index].details = details;
    },
    sortArr(arr, str) {
      var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;

      // 按照特定的参数将数组排序将具有相同值得排在一起
      arr = arr.sort(function (a, b) {
        var s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });

      if (arr.length) {
        _tmp = arr[0][str];
      }
      // console.log( arr );
      // 将相同类别的对象添加到统一个数组
      for (var i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      // 将最后的内容推出新数组
      _arr.push(_t); //获取到所有整合数据
      console.log(_arr); //可以得到图三
      //   // 终极整合
      //   var integration = [];
      //   _arr.forEach((item) => {
      //     var eee = { name: "", data: [] };
      //     item.forEach((self) => {
      //       if (eee.name != self.payType) {
      //         eee.name = self.payType;
      //         eee.data.push(self.money);
      //       } else {
      //         eee.data.push(self.money);
      //       }
      //     });
      //     integration.push(eee);
      //   });
      //   console.log(integration);//图二
      //   return integration;
      return _arr;
    },
    GoToWaitingOrder() {
      this.$router.push({
        name: "WaitingOrder",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.OrderMaterials {
  margin-top: 80px;
}
.formFlex {
  display: flex;
  width: 100%;
  /deep/ .ant-form-item {
    width: 30%;
  }
  //   justify-content: space-between;
}
.marginTop {
  margin-top: 50px;
}
.clickBtns {
  margin-top: 20px;
  .cancelBtn {
    margin-left: 20px;
  }
}
</style>
